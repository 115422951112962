<template>
  <loading v-if="loading" />
  <div v-else class="columns is-multiline">
    <div
      v-for="(p, index) in sortedProducts"
      :key="`product-${index}`"
      class="column is-12"
    >
      <product-card
        :product="p"
        :currency="currency"
        :class="{ 'is-selected': $_.isEqual(selectedProduct, p) }"
        @click.native="selectTerm(p)"
      />
    </div>
  </div>
</template>

<script>
import { collection, getDocs, query, where } from "@firebase/firestore";
export default {
  name: "SelectPlan",
  components: {
    "product-card": () => import("@shared/cart/_productCard")
  },
  props: {
    currency: {
      type: String,
      default: () => {
        return null;
      }
    },
    selectedProduct: {
      type: Object,
      required: false,
      default: () => {
        return null;
      }
    },
    selectedProductTerm: {
      type: Object,
      required: false,
      default: () => {
        return null;
      }
    }
  },
  data() {
    return {
      loading: true,
      products: {},
      product: this.selectedProduct,
      productTerms: {},
      productTerm: this.selectedProductTerm
    };
  },
  computed: {
    sortedProducts() {
      return this.$_.orderBy(this.products, ["order"], ["asc"]);
    }
  },
  watch: {
    product(product) {
      this.$emit("productSelected", product);
      this.selectTerm(product);
    }
  },
  created() {
    this.getProducts();
  },
  methods: {
    getProducts() {
      getDocs(
        query(
          collection(this.$firestore, "products"),
          where(`categories`, `array-contains`, `core`),
          where("isActive", "==", true)
        )
      ).then(snapshot => {
        if (snapshot.size) {
          snapshot.forEach(async product => {
            await this.getProductTerms(product);
          });
        }
        this.loading = false;
      });
    },
    getProductTerms(product) {
      return getDocs(
        query(collection(product.ref, "terms"), where("isActive", "==", true))
      ).then(snapshot => {
        if (!snapshot.empty) {
          this.$set(
            this.products,
            product.id,
            this.$_.merge({}, product.data(), {
              _productId: product.id
            })
          );
          snapshot.forEach(term => {
            this.$set(
              this.productTerms,
              term.id,
              this.$_.merge({}, term.data(), {
                _termId: term.id,
                _productId: product.id
              })
            );
          });
        }
      });
    },
    selectTerm(product) {
      const terms = this.$_.filter(this.productTerms, term => {
        return term._productId === product._productId;
      });
      if (terms.length > 1) {
        const modal = this.$modal.open({
          component: () => import("@shared/cart/_selectTerm"),
          parent: this,
          width: 540,
          hasModalCard: true,
          props: {
            currency: this.currency,
            selectedProductTerm:
              this.$_.get(this.selectedProductTerm, "_productId") ===
              product._productId
                ? this.selectedProductTerm
                : null,
            terms
          },
          events: {
            selected: term => {
              this.$emit("productSelected", product);
              this.$emit("productTermSelected", term);
              modal.close();
            }
          }
        });
      } else {
        this.$emit("productSelected", product);
        this.$emit("productTermSelected", terms[0]);
      }
    }
  }
};
</script>
